<template>
  <v-dialog
    v-model="dialog"
    width="900"
    persistent
    scrollable
  >
    <v-card
      :loading="load"
      :disabled="load"
    >
      <v-card-title class="pb-0">
        {{ nuevo ? 'Nuevo' : 'Ediatar' }} concepto
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-0" style="font-size: 18px">
        Vendedor {{ item.vendedor_nombre }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="6" sm="2" class="py-0">
              <v-autocomplete
                v-model="detalle.empresa"
                label="Empresa"
                item-value="id"
                item-text="nombre"
                :items="empresas"
                :rules="[$store.state.rules.required]"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
                @change="nuevo ? buscar() : ''"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-autocomplete
                v-model="detalle.concepto"
                label="Concepto"
                item-value="id"
                item-text="nombre"
                :items="conceptos"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
                @change="calcular_importe()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field
                v-model.trim="detalle.preimpreso"
                label="Suscripción"
                type="number"
                :rules="[$store.state.rules.required]"
                :readonly="!nuevo"
                :filled="!nuevo"
                validate-on-blur
                outlined
                dense
                @blur="nuevo ? buscar() : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="5" class="py-0">
              <v-text-field
                v-model.trim="detalle.titular"
                label="Titular"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="5" class="py-0">
              <v-text-field
                v-model.trim="detalle.descripcion"
                label="Modelo"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field-money
                v-model.trim="detalle.valor"
                label="Valor"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  rules: [$store.state.rules.required],
                  'validate-on-blur': true,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 0,
                  empty: null
                }"
                @blur="calcular_importe()"
              />
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field
                v-model.trim="detalle.porcentaje"
                label="Porcentaje"
                type="number"
                suffix="%"
                :rules="[$store.state.rules.required]"
                validate-on-blur
                outlined
                dense
                @blur="calcular_importe()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field-money
                v-model.trim="detalle.importe"
                label="Importe"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  outlined: true,
                  readonly: true,
                  filled: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
              />
            </v-col>
            <v-col cols="12" sm="8" class="py-0">
              <v-text-field
                v-model.trim="detalle.comentario"
                label="Observaciones"
                :hide-details="$vuetify.breakpoint.xs"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              :style="!$vuetify.breakpoint.xs ? 'padding-top: 6px' : ''"
              cols="12" sm="4"
            >
              <v-checkbox
                v-if="!nuevo || detalle.debito === 1 || detalle.debito === 0"
                v-model="detalle.debito"
                label="Débito automático"
                class="mt-0"
                :true-value="1"
                :false-value="0"
                hide-details
                readonly
                dense
              ></v-checkbox>
              <div v-else-if="detalle.preimpreso">
                Total liquidado: <strong>{{ (porcentaje * 100).toFixed(1) }}%</strong> - <strong>{{ formatMoney(total_liquidado) }}</strong>
                <v-icon
                  color="info"
                  title="Ver detalle"
                  small
                  right
                  @click="item_detalle.preimpreso = detalle.preimpreso; dialog_detalle = true"
                >
                  fas fa-eye
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4 pt-0">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <DetalleCartera
      v-model="dialog_detalle"
      :item="item_detalle"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { format_money } from '../../util/utils'
import DetalleCartera from './DetalleCartera'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      formatMoney: format_money,
      total_liquidado: 0,
      porcentaje: 0,
      dialog_detalle: false,
      nuevo: false,
      load: false,
      copy: null,
      item_detalle: {},
      detalle: {}
    }
  },
  props: {
    value: Boolean,
    item: Object
  },
  computed: {
    ...mapState(['empresas']),
    ...mapState('genericos', ['conceptos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    DetalleCartera,
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.item.preimpreso) {
          this.nuevo = false
          this.detalle = {
            id: this.item.id,
            empresa: this.item.empresa,
            concepto: this.item.concepto,
            preimpreso: this.item.preimpreso,
            titular: this.item.titular,
            descripcion: this.item.descripcion,
            valor: this.item.valor_venta,
            porcentaje: (this.item.porcentaje * 100).toFixed(1),
            importe: this.item.importe,
            debito: this.item.debito,
            comentario: this.item.comentario
          }
          this.copy = JSON.stringify(this.detalle)
        } else {
          this.nuevo = true
          this.detalle = {}
        }
      } else {
        this.$refs.form.resetValidation()
        this.total_liquidado = 0
        this.porcentaje = 0
        this.item_detalle = {}
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        if (this.nuevo) {
          await this.$store.dispatch('comisiones/nueva', this.detalle)
            .then((res) => {
              this.dialog = false
              this.$emit('nueva', res.data)
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo si modifico algo actualiza
          if (JSON.stringify(this.detalle) != this.copy) {
            await this.$store.dispatch('comisiones/editar', this.detalle)
              .then((res) => {
                this.dialog = false
                this.detalle.total = res.total
                this.detalle.total_liq = res.total_liq
                this.$emit('editar', this.detalle)
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el concepto',
              color: 'warning'
            })
          }
        }
        this.$store.state.loading = false
      }
    },
    async buscar () {
      if (!(this.detalle.empresa && this.detalle.preimpreso)) return
      this.$store.state.loading = true
      await this.$store.dispatch('comisiones/get_datos_nueva', {
        empresa: this.detalle.empresa,
        preimpreso: this.detalle.preimpreso,
        vendedor: this.item.vendedor
      })
        .then((res) => {
          this.total_liquidado = res.detalle.total_liquidado
          this.porcentaje = res.detalle.porcentaje
          this.item_detalle = res.detalle
          this.detalle = {
            liq_vend_id: this.item.liq_vend_id,
            empresa: this.detalle.empresa,
            concepto: this.detalle.concepto,
            preimpreso: this.detalle.preimpreso,
            titular: res.data.titular,
            descripcion: res.data.descripcion,
            modelo: res.data.modelo,
            valor: res.data.valor,
            porcentaje: this.detalle.porcentaje,
            importe: this.detalle.importe
          }
        })
        .catch(error => {
          this.detalle.preimpreso = null
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    calcular_importe () {
      if (this.detalle.porcentaje && this.detalle.valor && this.detalle.concepto) {
        this.detalle.importe = (parseFloat(this.detalle.porcentaje / 100) * parseFloat(this.detalle.valor)).toFixed(2) * this.conceptos.find(c => c.id == this.detalle.concepto).signo
      } else {
        this.detalle.importe = null
      }
    }
  }
}
</script>